import {helpers} from '@vuelidate/validators';

const phone = helpers.regex(/^0\d{3}(\s)?\d{3}(\s)?\d{3,4}|07\d{3}(\s)?\d{6}$/);
const postcode = helpers.regex(/^([A-Z](([0-9][0-9]?)|([A-Z][0-9][0-9]?)|([A-Z]?[0-9][A-Z])) ?[0-9][ABD-HJLNP-UW-Z]{2})$/i);
const vrm = helpers.regex(/(^[A-Z]{2}[0-9]{2}[\s]?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[\s]?[A-Z]{3}$)|(^[A-Z]{3}[\s]?[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[\s]?[A-Z]{1,2}$)|(^[0-9]{1,3}[\s]?[A-Z]{1,3}$)|(^[A-Z]{1,2}[\s]?[0-9]{1,4}$)|(^[A-Z]{1,3}[\s]?[0-9]{1,3}$)/i);
const notSameAs = (param: string) => (value: string) => !helpers.req(value) || value !== param;

export {
    phone,
    postcode,
    vrm,
    notSameAs,
};
